const envs = [
    {
    "legacy": false,
    "env": "dev",
    "endpoint": "/mt-dev",
    "api_url": "https://api.sbod.sre-mgmt.acrolinx-cloud.net",
    "description": "DEV Multitenant",
    "justifications": [
        "Standard - under 72 Hours",
        "Long Term",
        "Permanently Active"

    ]
},
{
    "legacy": false,
    "env": "stg",
    "endpoint": "/mt-stg",
    "api_url": "https://api.sbod.sre-mgmt.acrolinx-cloud.net",
    "description": "STG Multitenant",
    "justifications": [
        "Standard - under 72 Hours",
        "Long Term"
    ]
},
{
    "legacy": false,
    "env": "sandboxes",
    "endpoint": "/mt-prod",
    "api_url": "https://api.sbod.sre-mgmt.acrolinx-cloud.net",
    "description": "PROD Multitenant",
    "justifications": [
        "Standard - under 72 Hours",
        "Long Term"
    ]
}
]



export {envs}
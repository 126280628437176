/*
tableCols represent the main table columns, their accessors and sometimes, the contents
to be mapped to the cells. These are used by ReactTable component is ReactTable.js
*/

export const tableColsMT= [
    {
        Header: "#",
        accessor: "index",
        Cell: ({row})=>{
            return <div>{(row.index + 1)}</div>
        }
      },
      {
        Header: "Name",
        accessor: "hostname",
        Cell:({row})=>{
            return <div>{row.original.hostname}</div>
        }
       
      },
      {
        Header: "Region",
        accessor: "region"
       
      },
      {
        Header: "User",
        accessor: "user"
      },
       {
        Header: "Time Requested",
        accessor: "daysRequested",
      },
 
      {
        Header: "Time Since Start",
        accessor: "hoursSinceStart"


      },
      {
        Header: "Justification",
        accessor: "justification"
      },
      {
        Header: "Time Until Shutdown",
        accessor: "hoursTillShutdown",
      },
      {
        Header: "Start Time",
        accessor: "startTime"
        
        
      },

      {
        Header: "Current Status",
        accessor: "action",
        Cell:({row})=>{
          let timeNow = Math.floor(new Date().getTime() / 1000)
          let timeDiff = row.original.scheduled_start_time - timeNow
          let status = timeDiff <60?"on":"scheduled"
          return <div>{

            row.original.action==="start"?status:"off"}</div>
          }
       
      },
      {
        Header: "Note",
        accessor: "note"
      }
]
import { Text, Box, Stack, Heading } from '@chakra-ui/react'
export function UserCard(props){
return(
<Box
px={{
  base: '4',
  md: '6',
}}
py={{
  base: '5',
  md: '6',
}}
bg="bg-surface"
borderRadius="lg"
boxShadow="sm"

>
    <Stack alignItems={"flex-end"}>
        <Heading fontSize="sm">User: {props.email}</Heading>
        <Heading fontSize="sm">Access Level: {props.adminTypes==="0"?"Regular User (max 72 hours)": "Admin User"}</Heading>
    </Stack>

</Box>
) 
}

import {
    Box,
    Flex,
    Stack,
    useColorModeValue,
    Image,
  } from '@chakra-ui/react';
  import {Link as Reactlink} from 'react-router-dom'
  import logo from '../images/acrolinx_logo.png'

  
  export default function WithSubnavigation(props) {
  
    return (
      <Box className='NavbarBox'>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}>

          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Box>
              <Reactlink to={"/"}>
              <Image className='acrolinx_logo'
                objectFit='cover'
                src={logo}
                alt='Acrolinx GmbH Logo'
          />
              </Reactlink>
            </Box>
          </Flex>
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
                     {props.signInOut}
          
          </Stack>
        </Flex>
      </Box>
    );
  }